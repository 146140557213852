import { template as template_5df828eb2e9c44ed8dc0dc775637e696 } from "@ember/template-compiler";
const WelcomeHeader = template_5df828eb2e9c44ed8dc0dc775637e696(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
